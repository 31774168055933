@import './../import.css';

.search-result-empty {
  width: 100%;
  margin: 0 0 80px 0;
  position: relative;
  padding: 32px;
  background: var(--color-surface);
  box-sizing: border-box;
  &__image {
    display: none;
  }
  &__text {
    &-title {
      color: var(--color-surface-text);
      @mixin font 500, 18px, 42px, 24px, 48px, var(--font-title-secondary);
    }
    &-desc {
      margin: 14px 0 0 0;
      color: var(--color-surface-text);
      @mixin font normal, 14px, 18px, 18px, 24px, var(--font);
      & a {
        color: var(--color-primary);
        &:hover {
          color: var(--color-primary-hover);
        }
      }
    }
  }
}
