.search {
  &&_type_quick {
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    z-index: 100;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    opacity: 0;
    &::after {
      width: 100%;
      height: 100%;
      background: #fff;
      position: fixed;
      content: '';
      display: none;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 1;
    }
  }
}
