@import './../import.css';

.search-result {
  width: 100%;
  margin: 0 0 80px 0;
  position: relative;
  &__item {
    padding: 10px 20px 10px 20px;
    position: relative;
    &:hover {
      background: rgb(246, 246, 246);
    }
    &-link {
      color: #000;
      &:hover {
        color: #d0021b;
      }
    }
    &-title {
      font: 16px/16px var(--font);
      color: #000;
      display: block;
      white-space: -moz-pre-wrap !important;
      white-space: -webkit-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
    }
    &_state_current {
      background: rgb(246, 246, 246);
    }
  }
}
