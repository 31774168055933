var(--font);

.search {
  width: 100%;
  position: relative;
  &__wrapper {
    width: 100%;
    padding: 30px 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    opacity: 100;
  }
  &__close {
    width: 40px;
    height: 40px;
    color: var(--color-surface-item);
    text-decoration: none;
    position: absolute;
    right: 0;
    top: -56px;
    display: block;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
    }
    &::before {
      width: 100%;
      height: 3px;
      background: var(--color-surface-item);
      position: absolute;
      content: '';
      display: block;
      top: 50%;
      right: 0;
      transform: rotate(-45deg);
    }
    &::after {
      width: 100%;
      height: 3px;
      background: var(--color-surface-item);
      position: absolute;
      content: '';
      display: block;
      top: 50%;
      right: 0;
      transform: rotate(45deg);
    }
  }
  &__icon {
    width: 20px;
    height: 20px;
    &::before {
      font: 18px/14px 'FontAwesome', sans-serif;
      color: #000;
      content: '\f002';
      display: block;
      z-index: 2;
    }
  }
}

input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}
