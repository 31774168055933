@define-mixin font $weight, $minFont, $maxFont, $minLine, $maxLine, $family {
  font-size: responsive $minFont $maxFont;
  font-range: var(--min-max);
  font-weight: $weight;
  font-family: $family;
  line-height: responsive $minLine $maxLine;
  line-height-range: var(--min-max);
}

@define-mixin dark {
  @media (prefers-color-scheme: dark) {
    & {
      @mixin-content;
    }
  }
}

@define-mixin light {
  @media (prefers-color-scheme: light) {
    & {
      @mixin-content;
    }
  }
  @mixin-content;
}

@define-mixin disableAnimation {
  transform: translate(0, 0);
  opacity: 1;
}
