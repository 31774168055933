var(--font);

.search-autocomplete {
  &.autocomplete-suggestions {
    width: 100%;
    position: relative;
    &::after {
      background: #fff;
      border-radius: 2px;
      position: absolute;
      content: '';
      box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.4);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    & .autocomplete-suggestion {
      padding: 10px 20px;
      position: relative;
      z-index: 2;
      &:hover {
        background: #eee;
      }
    }
    & .autocomplete-selected {
      background: #eee;
    }
  }
}
