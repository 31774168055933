var(--font);

.search-filter {
  width: 100%;
  margin: 0 0 30px 10px;
  position: relative;
  &__item {
    font: 14px/14px var(--font);
    color: #777;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
