var(--font);

.search-loading {
  width: 100%;
  text-align: center;
  margin: 20px 0 0 0;
  display: none;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  &_state_visible {
    display: block;
    animation-name: searchLoadingFadeIn;
  }
  &_state_hidden {
    animation-name: searchLoadingFadeOut;
  }
  & .sk-three-bounce {
    & .sk-child {
      background-color: var(--color-primary);
    }
  }
}

@keyframes searchLoadingFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes searchLoadingFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
