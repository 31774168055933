var(--font);

.search-icon {
  display: block;
  &&_type_link {
    width: 100%;
    height: 100%;
    font-size: 0;
    text-decoration: none;
    display: block;
  }
}
