var(--font);

.search-input {
  width: 100%;
  position: relative;
  display: flex;
  &__icon {
    height: 100%;
    min-width: 48px;
    background: url('./../img/icon/search.svg') no-repeat 50% 50%;
    &:hover {
      background: var(--color-primary-hover) url('./../img/icon/search.svg') no-repeat 50% 50%;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &__input {
    width: 100%;
    color: var(--color-surface-text);
    background: var(--color-surface);
    display: block;
    box-sizing: border-box;
    @mixin font normal, 16px, 24px, 16px, 24px, var(--font-title-secondary);
    padding: 12px 24px;
    border: solid 1px var(--color-surface-border);
    &:focus {
      outline: 0;
    }
  }
  &__clear {
    width: 16px;
    height: 16px;
    position: absolute;
    display: none;
    top: 50%;
    right: 15px;
    z-index: 2;
    transform: translate(0, -50%);
    transition: all 0.1s ease-in-out;
    opacity: 0.5;
    cursor: pointer;
    &::before,
    &::after {
      width: 20px;
      height: 2px;
      background: var(--color-surface-item);
      position: absolute;
      content: '';
      display: block;
      left: calc(50% - 10px);
      top: calc(50% - 1px);
    }
    &::before {
      background: var(--color-surface-item);
      transform: rotate(-45deg);
    }
    &::after {
      background: var(--color-surface-item);
      transform: rotate(45deg);
    }
    &:hover {
      opacity: 1;
    }
    &_state_hidden {
      display: none;
    }
  }
}

