.search {
  &_type_quick {
    ^&&&_state_visible {
      animation-name: quickSearchFadeIn;
      @media (--mobile) {
        animation-name: quickSearchFadeInMobile;
      }
      & .search {
        &-input {
          &__icon {
            cursor: pointer;
            &:hover {
              transform: scale(1);
            }
          }
          &__input {
            visibility: visible;
            animation-name: quickSearchInputFadeIn;
            @media (--mobile) {
              animation: none;
            }
          }
        }
      }
    }
  }
}

@keyframes quickSearchFadeIn {
  from {
    width: 45px;
  }
  to {
    width: 100%;
  }
}

@keyframes quickSearchFadeInMobile {
  from {
    width: 45px;
  }
  to {
    width: 92%;
    right: 4%;
  }
}

@keyframes quickSearchInputFadeIn {
  from {
    border: solid 1px var(--color-white);
  }
  to {
    border: solid 1px var(--color-gray);
  }
}
